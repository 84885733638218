import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { api } from '../config/api.config';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { RolesService } from 'app/core/service/role.service';
import { DataUtils } from 'app/core/helpers/data.utils';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authenticated: boolean = false;
  private _httpClient = inject(HttpClient);
  private _userService = inject(UserService);
  private _rolesService = inject(RolesService);
  private _dataUtils = inject(DataUtils);
  private _code: BehaviorSubject<any | null> = new BehaviorSubject(null);

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Getter authenticated
   */
  get authenticate(): boolean {
    return this._authenticated;
  }

  set authenticate(value: boolean) {
    this._authenticated = value;
  }

  /**
   * Getter for code
   */
  get code$(): Observable<any> {
    return this._code.asObservable();
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    const headerDict = {
      subPath: 'reset-password',
    };

    const headers = new HttpHeaders(headerDict);

    return this._httpClient.post(api.forgotPassword, { email }, { headers });
  }

  /**
   * Reset password
   *
   * @param password
   * @param user
   */
  resetPassword(user: string, password: string): Observable<any> {
    return this._httpClient.post(api.resetPassword, { user, password });
  }

  /**
   * Reset password validation code
   *
   * @param code
   */
  resetPasswordValidationCode(code: string): Observable<any> {
    return this._httpClient.post(api.validationCode, { code }).pipe(
      tap((response) => {
        this._code.next(response);
      }),
    );
  }

  /**
   * Confirmation code
   *
   * @param code
   */
  confirmationCode(code: string): Observable<any> {
    return this._httpClient.post(api.confirmationCode, { code }).pipe(
      tap((response) => {
        this._code.next(response);
      }),
    );
  }

  /**
   * Change password
   *
   * @param userId
   * @param currentPassword
   * @param newPassword
   */
  changePassword(
    userId: string,
    currentPassword: string,
    newPassword: string,
  ): Observable<any> {
    return this._httpClient
      .post(api.changePassword, {
        user_id: userId,
        current_password: currentPassword,
        new_password: newPassword,
      })
      .pipe(switchMap((response: any) => of(response)));
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: {
    username: string;
    password: string;
    rememberMe: boolean;
  }): Observable<any> {
    // Throw error if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post(api.signIn, credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        const decoded = AuthUtils.decodeToken(response.access_token);

        this._rolesService.update(decoded.realm_access.roles);

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        this._dataUtils.accessToken = response.access_token;
        this._dataUtils.refreshToken = response.refresh_token;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient.post(api.refreshToken, {
      token: this._dataUtils.refreshToken,
    });
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    this._dataUtils.deleteAll();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Remove cookies
   */
  removeCookies(): void {
    this._dataUtils.deleteAll();
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    first_name: string;
    last_name: string;
    agreements: boolean;
  }): Observable<any> {
    const headerDict = {
      subPath: 'confirmation',
    };

    const headers = new HttpHeaders(headerDict);
    return this._httpClient.post(api.users, user, { headers });
  }

  /**
   * Reinvite
   *
   * @param user
   */
  reInvite(user: string): Observable<any> {
    const headerDict = {
      subPath: 'confirmation',
    };

    const headers = new HttpHeaders(headerDict);
    return this._httpClient.put(
      api.users + '/' + user + '/reinvite',
      {},
      { headers },
    );
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post(api.unlockSession, credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }
    // Check the access token availability
    if (!this._dataUtils.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this._dataUtils.accessToken)) {
      return this.signInUsingToken();
    }

    // If the access token exists, and it didn't expire, sign in using it
    return of(true);
  }
}
