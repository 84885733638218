import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { UserService } from '../user/user.service';
import { Router } from '@angular/router';
import { RolesService } from '../service/role.service';
import { DataUtils } from '../helpers/data.utils';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const _authService = inject(AuthService);
  const _userService = inject(UserService);
  const _rolesService = inject(RolesService);
  const _dataUtils = inject(DataUtils);
  const _router = inject(Router);

  // Clone the request object
  let newReq = req.clone();

  // Request
  //
  // If the access token didn't expire, add the Authorization header.
  // We won't add the Authorization header if the access token expired.
  // This will force the server to return a "401 Unauthorized" response
  // for the protected API routes which our response interceptor will
  // catch and delete the access token from the local storage while logging
  // the user out from the app.
  const accessToken = _dataUtils.accessToken;
  if (accessToken && !AuthUtils.isTokenExpired(accessToken)) {
    newReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
    });
  }

  // Response
  return next(newReq).pipe(
    catchError((error) => {
      if (
        error instanceof HttpErrorResponse &&
        !req.url.includes('/sign-in') &&
        error.status === 401
      ) {
        // Sign out
        return _authService.signInUsingToken().pipe(
          switchMap((response: any) => {
            // If there is an error response, the access token must have expired.
            // Try to re-sign in the user with their credentials
            if (!response) {
              return of(response);
            }

            // Store the access token in the local storage
            _dataUtils.accessToken = response.access_token;
            _dataUtils.refreshToken = response.refresh_token;

            const decoded = AuthUtils.decodeToken(response.access_token);

            _rolesService.update(decoded.roles);

            // Set the authenticated flag to true
            _authService.authenticate = true;

            // Store the user on the user service
            _userService.user = response.user;

            newReq.headers.set(
              'Authorization',
              `Bearer ${response.access_token}`,
            );
            return next(newReq);
          }),
          catchError(() => {
            // Return false
            _authService.signOut();
            _router.navigate(['/sign-in']);
            return throwError(error);
          }),
        );
      }

      return throwError(error);
    }),
  );
};
