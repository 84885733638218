/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    subtitle: 'Unique dashboard designs',
    type: 'group',
    icon: 'heroicons_outline:squares-2x2',
    children: [
      {
        id: 'dashboards.main',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:squares-2x2',
        link: '/dashboard',
      },
    ],
  },
  {
    id: 'divider-2',
    type: 'divider',
  },
  {
    id: 'users',
    title: 'Users',
    subtitle: 'Unique dashboard designs',
    type: 'group',
    icon: 'heroicons_outline:users',
    children: [
      {
        id: 'users.main',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/users',
      },
    ],
  },
  {
    id: 'divider-2',
    type: 'divider',
  },
  {
    id: 'movies',
    title: 'Movies',
    subtitle: 'Unique dashboard designs',
    type: 'group',
    icon: 'heroicons_outline:film',
    children: [
      {
        id: 'movies.main',
        title: 'Movie',
        type: 'basic',
        icon: 'heroicons_outline:film',
        link: '/movies/home',
      },
      {
        id: 'movies.rating',
        title: 'Rating',
        type: 'basic',
        icon: 'heroicons_outline:star',
        link: '/movies/rating',
      },
      {
        id: 'movies.comments',
        title: 'Comments',
        type: 'basic',
        icon: 'heroicons_outline:chat-bubble-left-ellipsis',
        link: '/movies/comments',
      },
    ],
  },
  {
    id: 'divider-2',
    type: 'divider',
  },
  {
    id: 'shows',
    title: 'Shows',
    subtitle: 'Unique dashboard designs',
    type: 'group',
    icon: 'heroicons_outline:tv',
    children: [
      {
        id: 'shows.main',
        title: 'Shows',
        type: 'basic',
        icon: 'heroicons_outline:film',
        link: '/shows/home',
      },
      {
        id: 'shows.seasons',
        title: 'Seasons',
        type: 'basic',
        icon: 'heroicons_outline:list-bullet',
        link: '/shows/seasons',
      },
      {
        id: 'shows.episodes',
        title: 'Episodes',
        type: 'basic',
        icon: 'heroicons_outline:play',
        link: '/shows/episodes',
      },
      {
        id: 'shows.rating',
        title: 'Rating',
        type: 'basic',
        icon: 'heroicons_outline:star',
        link: '/shows/rating',
      },
      {
        id: 'shows.comments',
        title: 'Comments',
        type: 'basic',
        icon: 'heroicons_outline:chat-bubble-left-ellipsis',
        link: '/shows/comments',
      },
    ],
  },
  {
    id: 'dashboards',
    title: 'Dashboards',
    subtitle: 'Unique dashboard designs',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [
      {
        id: 'dashboards.project',
        title: 'Project',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-check',
        link: '/dashboards/project',
      },
      {
        id: 'dashboards.analytics',
        title: 'Analytics',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboards/analytics',
      },
      {
        id: 'dashboards.finance',
        title: 'Finance',
        type: 'basic',
        icon: 'heroicons_outline:banknotes',
        link: '/dashboards/finance',
      },
      {
        id: 'dashboards.crypto',
        title: 'Crypto',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/dashboards/crypto',
      },
    ],
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    tooltip: 'Dashboards',
    type: 'aside',
    icon: 'heroicons_outline:squares-2x2',
    children: [],
  },
  {
    id: 'dashboards',
    title: 'Dashboards',
    tooltip: 'Dashboards',
    type: 'aside',
    icon: 'heroicons_outline:home',
    children: [],
  },
  {
    id: 'users',
    title: 'Users',
    tooltip: 'Users',
    type: 'aside',
    icon: 'heroicons_outline:users',
    children: [],
  },
  {
    id: 'movies',
    title: 'Movies',
    tooltip: 'Movies',
    type: 'aside',
    icon: 'heroicons_outline:film',
    children: [],
  },
  {
    id: 'shows',
    title: 'Shows',
    tooltip: 'Shows',
    type: 'aside',
    icon: 'heroicons_outline:tv',
    children: [],
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'DASHBOARDS',
    type: 'group',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'users',
    title: 'Users',
    tooltip: 'Users',
    type: 'aside',
    icon: 'heroicons_outline:users',
    children: [],
  },
  {
    id: 'movies',
    title: 'Movies',
    tooltip: 'Movies',
    type: 'aside',
    icon: 'heroicons_outline:film',
    children: [],
  },
  {
    id: 'shows',
    title: 'Shows',
    tooltip: 'Shows',
    type: 'aside',
    icon: 'heroicons_outline:tv',
    children: [],
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    type: 'group',
    icon: 'heroicons_outline:home',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'apps',
    title: 'Apps',
    type: 'group',
    icon: 'heroicons_outline:qrcode',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'heroicons_outline:document-duplicate',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'user-interface',
    title: 'UI',
    type: 'group',
    icon: 'heroicons_outline:rectangle-stack',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'navigation-features',
    title: 'Misc',
    type: 'group',
    icon: 'heroicons_outline:bars-3',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
