import { environment } from '../../../environments/environment';

const url = `${environment.host}/${environment.prefix}`;
export const domainName = environment.domain;

/**
 * API settings
 */
export const api = {
  // Authentication API
  signIn: url + '/sign-in',
  signUp: url + '/sign-up',
  unlockSession: url + '/unlock-session',
  refreshToken: url + '/refresh-token',
  forgotPassword: url + '/forgot-password',
  resetPassword: url + '/reset-password',
  validationCode: url + '/validation-code',
  confirmationCode: url + '/confirmation',
  changePassword: url + '/change-password',
  changeLanguage: url + '/change-language',
  changeLayout: url + '/change-layout',

  // Common API
  collaborator: url + '/collaborators',
  offer: url + '/offers',
  genre: url + '/genres',
  language: url + '/languages',
  tag: url + '/tags',

  // Movies API
  movie: url + '/movies',
  movieMedia: url + '/movie-mediaFiles',
  movieVideo: url + '/movie-videoFiles',
  movieSubtitle: url + '/movie-subtitleFiles',

  // Shows API
  show: url + '/shows',
  season: url + '/seasons',
  episode: url + '/episodes',
  episodeMedia: url + '/episode-mediaFiles',
  episodeVideo: url + '/episode-videoFiles',
  episodeSubtitle: url + '/episode-subtitleFiles',

  // Dashboard
  dashboardDistributionIncident: url + '/dashboard-distribution/incidents',
  dashboardDistributionInvestigation:
    url + '/dashboard-distribution/investigations',
  dashboardDistributionAction: url + '/dashboard-distribution/actions',
  dashboardDistributionTask: url + '/dashboard-distribution/tasks',
  dashboardRiskSeries: url + '/dashboard-risk-series',
  dashboardRiskProcessSeries: url + '/dashboard-risk-process-series',
  dashboardIncidentSeries: url + '/dashboard-incident-series',
  dashboardInvestigationSeries: url + '/dashboard-investigation-series',
  dashboardSummaryInvestigation: url + '/dashboard-summary-investigation',
  dashboardSummaryIncident: url + '/dashboard-summary-incident',
  dashboardSummaryIncidentAction: url + '/dashboard-summary-incident-action',
  dashboardSummaryRisk: url + '/dashboard-summary-risk',
  dashboardSummaryTask: url + '/dashboard-summary-task',
  dashboardSummaryActivity: url + '/dashboard-summary-activity',
  dashboardSummaryProcess: url + '/dashboard-summary-process',

  // User API
  currentUser: url + '/user',
  user: url + '/user',
  users: url + '/users',
  dashboardUsers: url + '/dashboard-users',
  groups: url + '/groups',

  // Notifications API
  notifications: url + '/notifications',

  // MailingModel lists API
  mailings: url + '/mailing_lists',
  mailingUsers: url + '/mailing_users',
};
