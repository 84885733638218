import { inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class DataUtils {
  private _cookieService = inject(CookieService);

  /**
   * Token split
   *
   */
  splitToken(token: string): string[] {
    const maxLength: number = 1000;
    const result: string[] = [];

    for (let i = 0; i < token.length; i += maxLength) {
      result.push(token.substring(i, i + maxLength));
    }

    return result;
  }

  get refreshToken(): string {
    const part: number = Number(this._cookieService.get('x-refresh-len'));
    let token: string = '';
    for (let i = 0; i < part; i++) {
      token += this._cookieService.get(`x-refresh-${i}`);
    }
    return token;
  }

  set refreshToken(token: string) {
    const tokenParts: string[] = this.splitToken(token);
    const part: number = tokenParts.length;
    this._cookieService.set('x-refresh-len', part.toString());
    tokenParts.map((item, index) => {
      this._cookieService.set(`x-refresh-${index}`, item, 10, '/');
    });
  }

  get accessToken(): string {
    const part: number = Number(this._cookieService.get('x-access-len'));
    let token: string = '';
    for (let i = 0; i < part; i++) {
      token += this._cookieService.get(`x-access-${i}`);
    }
    return token;
  }

  set accessToken(token: string) {
    // console.log(new Blob([token]).size, '😀');
    const tokenParts: string[] = this.splitToken(token);
    const part: number = tokenParts.length;
    this._cookieService.set('x-access-len', part.toString());
    tokenParts.map((item, index) => {
      this._cookieService.set(`x-access-${index}`, item, 10, '/');
    });
  }

  getData(key: string): string {
    return this._cookieService.get(key) ?? '';
  }

  setData(key: string, token: string) {
    this._cookieService.set(key, token);
  }

  /**
   * Return access token
   *
   * @return
   */

  deleteAll(): void {
    this._cookieService.deleteAll();
  }
}
